import React, { useState, useEffect, useRef } from "react";
import { Mic, Trash2, StopCircle, Send } from "lucide-react";
import AnimatedBackground from "../AnimatedBackground/AnimatedBackground";
import "./AIChatbot.css";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import logo from "../../assets/logo-techlab-white.png";

const ASK_ENDPOINT = "https://fn-np-tl-askai-rag.azurewebsites.net/api/askAi";

export default function AIChatbot() {
  const [language, setLanguage] = useState<"en" | "es">("es");
  const [chatStarted, setChatStarted] = useState(false);
  const [messages, setMessages] = useState<
    { text: string; sender: "user" | "bot" }[]
  >([]);
  const [isRecording, setIsRecording] = useState(false);
  const [query, setQuery] = useState("");
  const [response, setResponse] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    transcript,
    resetTranscript,
    listening,
    browserSupportsSpeechRecognition,
  } = useSpeechRecognition();

  useEffect(() => {
    if (transcript) {
      setQuery(transcript);
    }
  }, [transcript]);

  useEffect(() => {
    let silenceTimeout: NodeJS.Timeout;

    if (listening) {
      silenceTimeout = setTimeout(() => {
        SpeechRecognition.stopListening();
        setIsRecording(false);
        if (query.trim() !== "") {
          askApi();
        }
      }, 3000);
    }

    return () => {
      clearTimeout(silenceTimeout);
    };
  }, [listening, query]);

  const translations = {
    en: {
      welcome:
        "Welcome! I'm LyrIATech, your AI-powered virtual assistant, ready to help",
      start: "Start Chat",
      voiceChat: "Voice Chat",
      stopRecording: "Stop Recording",
      clear: "Clear Chat",
      send: "Send",
      placeholder: "Type your message here...",
      stopAvatar: "Stop Avatar",
    },
    es: {
      welcome:
        "¡Bienvenido! Soy LyrIATech tu asistente virtual impulsado por IA, listo para ayudarte",
      start: "Iniciar Chat",
      voiceChat: "Chat de Voz",
      stopRecording: "Detener Grabación",
      clear: "Limpiar Chat",
      send: "Enviar",
      placeholder: "Escribe tu mensaje aquí...",
      stopAvatar: "Detener Avatar",
    },
  };

  const t = translations[language];

  const messagesEndRef = React.useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const toggleLanguage = () => {
    setLanguage((prev) => {
      const newLanguage = prev === "en" ? "es" : "en";
      if (chatStarted) {
        setMessages((prevMessages) => [
          { text: translations[newLanguage].welcome, sender: "bot" },
          ...prevMessages.slice(1),
        ]);
      }
      return newLanguage;
    });
  };

  const startChat = () => {
    setChatStarted(true);
    setMessages([{ text: t.welcome, sender: "bot" }]);
  };

  const clearChat = () => {
    setMessages([]);
    setQuery("");
    setResponse("");
    resetTranscript();
  };

  const askApi = async () => {
    const currentQuery = query.trim();
    if (currentQuery !== "") {
      setLoading(true);

      setMessages((prev) => [...prev, { text: currentQuery, sender: "user" }]);
      setQuery("");
      resetTranscript();

      try {
        const lastMessages = messages.slice(-15);
        const conversationHistory = lastMessages
          .map((msg) => `${msg.sender === "bot" ? "bot" : "user"}: ${msg.text}`)
          .join("\n");

        const fullContext = `${conversationHistory}\nuser: ${currentQuery}`;

        const languagePrompt =
          language === "en"
            ? "Please respond in English."
            : "Por favor, responde en español.";

        const fullQuery = `${fullContext}\n${languagePrompt}`;
        console.log("query with context:", fullQuery);

        // Prompt Template must maintain {query} and {sources} placeholders
        const promptTemplate =
          "You are a friendly assistant that answers questions about emerging technology reports. Try to make your answers fun! Answer the query using only the sources provided below in a friendly a concise matter using a paragraph at most. Always Answer using the SAME language of the query. Answer ONLY with the facts listed in the list of sources below. If there isn't enough information below, say you don't know. Do not generate answers that don't use the sources below. Query: {query} Sources:\n{sources}";

        const request = await fetch(
          `${ASK_ENDPOINT}?query=${encodeURIComponent(
            fullQuery
          )}&prompt=${encodeURIComponent(
            promptTemplate
          )}&index=vector-techreports-techlab`,
          {
            method: "GET",
          }
        );

        if (request.status === 200 || request.status === 201) {
          const message = await request.json();
          if (message) {
            let content = message.content;
            content = content.replaceAll(/\[(.*?)\]/g, "");
            setResponse(content);
            setMessages((prev) => [...prev, { text: content, sender: "bot" }]);

            window.globalSpeak(content);
          }
        } else if (request.status === 503) {
          handleErrorResponse(
            "Hubo un problema con el servicio de Inteligencia Artificial. Por favor intenta nuevamente."
          );
        } else {
          handleErrorResponse(
            "Ocurrió un error inesperado. Por favor, inténtalo de nuevo más tarde."
          );
        }
      } catch (error) {
        console.error("Error en la solicitud:", error);
        handleErrorResponse(
          "No se pudo conectar con el servidor. Por favor, verifica tu conexión a internet e inténtalo de nuevo."
        );
      } finally {
        setLoading(false);
      }
    }
  };

  const handleErrorResponse = (errorMessage: string) => {
    setResponse(errorMessage);
    setMessages((prev) => [
      ...prev,
      { text: query, sender: "user" },
      { text: errorMessage, sender: "bot" },
    ]);
    window.globalSpeak(errorMessage);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter" && query.trim() !== "") {
      askApi();
    }
  };

  useEffect(() => {
    const avatarElement = document.getElementById("avatar");
    const avatarContainer = document.getElementById("avatar-container");

    if (avatarElement && avatarContainer) {
      avatarContainer.appendChild(avatarElement);
    }

    return () => {
      const body = document.body;
      if (avatarElement && body) {
        body.appendChild(avatarElement);
      }
    };
  }, []);

  const toggleRecording = () => {
    if (!browserSupportsSpeechRecognition) {
      alert("Tu navegador no soporta el reconocimiento de voz.");
      return;
    }

    if (listening) {
      SpeechRecognition.stopListening();
    } else {
      resetTranscript();
      setQuery("");
      SpeechRecognition.startListening({
        continuous: true,
        language: language === "en" ? "en-US" : "es-ES",
      });
    }
    setIsRecording(!listening);
  };

  const stopAvatarSpeak = () => {
    window.globalStopSpeak();
  };

  return (
    <div className="chatbot-container">
      <AnimatedBackground />
      <div className="chatbot-content">
        <header className="chatbot-header">
          <h1
            style={{
              marginLeft: "20px",
              fontFamily: "unset",
              fontSize: "50px",
            }}
          >
            LyrIATech
          </h1>
          <div style={{ display: "flex" }}>
            <img src={logo} alt="Logo" className="header-logo" />
            <button onClick={toggleLanguage} className="language-toggle">
              {language === "es" ? "EN" : "ES"}
            </button>
          </div>
        </header>
        <main className="chatbot-main">
          <div className="avatar-section">
            <div
              id="avatar-container"
              style={{ height: "600px", width: "550px", marginTop: "-5px" }}
            ></div>
            {/* Moved buttons here */}
            {chatStarted && (
              <div className="avatar-controls">
                <button
                  onClick={toggleRecording}
                  className={`voice-chat-btn ${listening ? "recording" : ""}`}
                >
                  {listening ? <StopCircle size={20} /> : <Mic size={20} />}
                  <span>{listening ? t.stopRecording : t.voiceChat}</span>
                </button>

                <button onClick={stopAvatarSpeak} className="stop-avatar-btn">
                  <StopCircle size={20} />
                  <span>{t.stopAvatar}</span>
                </button>
              </div>
            )}
          </div>
          <div className="chat-section">
            {!chatStarted ? (
              <div className="welcome-screen">
                <p>{t.welcome}</p>
                <button onClick={startChat} className="start-chat-btn">
                  {t.start}
                </button>
              </div>
            ) : (
              <>
                <div className="chat-controls">
                  <button onClick={clearChat} className="clear-chat-btn">
                    <Trash2 size={20} />
                    <span>{t.clear}</span>
                  </button>
                </div>
                <div className="messages-container">
                  {messages.map((message, index) => (
                    <div
                      key={index}
                      className={`message ${
                        message.sender === "user"
                          ? "user-message"
                          : "bot-message"
                      }`}
                    >
                      {message.text}
                    </div>
                  ))}
                  <div ref={messagesEndRef} />
                </div>
                <div className="message-input">
                  <input
                    type="text"
                    value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    onKeyDown={handleKeyPress}
                    placeholder={t.placeholder}
                    className="text-input"
                  />
                  <button className="send-btn" onClick={askApi} title={t.send}>
                    <Send size={24} />
                  </button>
                </div>
              </>
            )}
          </div>
        </main>
      </div>
    </div>
  );
}