import React from "react";
import "./AnimatedBackground.css";

export default function AnimatedBackground() {
  const colors = ["#FF6B6B", "#4ECDC4", "#45B7D1", "#FFA07A", "#98D8C8"];

  return (
    <div className="animated-background">
      {[...Array(30)].map((_, i) => (
        <div
          key={`light-${i}`}
          className="floating-light"
          style={
            {
              "--size": `${100 + Math.random() * 150}px`,
              "--x": `${Math.random() * 100}vw`,
              "--y": `${Math.random() * 100}vh`,
              "--duration": `${30 + Math.random() * 40}s`,
              "--delay": `${-Math.random() * 40}s`,
              "--color": colors[Math.floor(Math.random() * colors.length)],
            } as React.CSSProperties
          }
        ></div>
      ))}
    </div>
  );
}
